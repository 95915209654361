<script setup lang="ts">
import CloseIcon from '@assets/icons/cross.svg?component';
import PlusIcon from '@assets/icons/plus.svg?component';
import SearchIcon from '@assets/icons/search.svg?component';
import SpinnerIcon from '@assets/icons/spinner.svg?component';
import { BaseInputGroup } from '@components/base';
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';

type Props = {
  modelValue?: any[]
  options?: any[]
  loading?: boolean
  searchPlaceholder?: string
  searchInputPlaceholder?: string
  minLength?: number
  minLengthMessage?: string
  noResultsMessage?: string
  optionLabel?: (option: any) => string
  customValue?: (value: string) => any
  multiple?: boolean
  trackBy?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  options: () => [],
  loading: false,
  minLength: 3,
  searchPlaceholder: '',
  searchInputPlaceholder: '',
  minLengthMessage: '',
  noResultsMessage: 'No results',
  optionLabel: undefined,
  customValue: undefined,
  multiple: false,
  trackBy: 'value',
});

const emit = defineEmits<{
  'update:modelValue': [value?: any[]]
  search: [value: string]
}>();

const isOpen = ref(false);
const searchValue = ref('');
const userInputValue = ref('');
const isInput = ref(false);
const searchInputRef = ref<HTMLInputElement | null>(null);
const innerLoading = ref(props.loading);
const innerOptions = ref(props.options);
const dropdownRef = ref<HTMLElement | null>(null);

watch(() => props.options, (options) => {
  innerOptions.value = options;
});

watch(() => props.loading, (loading) => {
  innerLoading.value = loading;
});

const isSelected = (option: any) => {
  return !!props.modelValue?.some((v) => {
    return v[props.trackBy] === option[props.trackBy];
  });
};

const open = () => {
  isOpen.value = true;
};

const close = () => {
  isOpen.value = false;
  isInput.value = false;
  searchValue.value = '';
  userInputValue.value = '';
  innerOptions.value = [];
  searchInputRef.value?.blur();
};

const addValue = (value: any) => {
  if (!props.modelValue || isSelected(value)) {
    return;
  }
  const newValue = props.multiple ? [...props.modelValue, value] : [value];
  emit('update:modelValue', newValue);
};

const deleteValue = (value: any) => {
  const newValue = props.modelValue?.filter((v) => v[props.trackBy] !== value[props.trackBy]);

  if (!newValue?.length) {
    emit('update:modelValue', undefined);
    return;
  }

  emit('update:modelValue', newValue);
};

watch(searchValue, (value) => {
  if (value.length < props.minLength) {
    innerLoading.value = false;
    innerOptions.value = [];
    return;
  }
  innerLoading.value = true;
  emit('search', value);
});

watch(isInput, async (isInput) => {
  if (!dropdownRef.value) {
    return;
  }
  await nextTick();
  if (isInput) {
    dropdownRef.value!.scrollTop = dropdownRef.value!.scrollHeight + 100;
  }
});

const onEscape = ({ key }: KeyboardEvent) => {
  if (key === 'Escape') {
    close();
  }
};

onMounted(() => {
  window.addEventListener('keydown', onEscape);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onEscape);
});

const getOptionLabel = (option: any) => {
  if (!props.optionLabel) {
    return option;
  }
  return props.optionLabel(option);
};
</script>

<template>
  <div class="dropdown-search">
    <BaseInputGroup
      v-click-outside="close"
      class="dropdown-search__input-group"
      :label="searchPlaceholder"
      :is-lock="isOpen || searchValue.length !== 0"
    >
      <input
        ref="searchInputRef"
        v-model="searchValue"
        class="dropdown-search__input input w-full !shadow-none"
        :class="{ '!outline-none !outline-0': isOpen }"
        type="text"
        @click="open"
      >
      <div
        class="dropdown-search__search"
        :class="[{ 'text-turquoise': isOpen }, { 'text-warm-grey': !isOpen }]"
      >
        <SearchIcon class="h-20 w-20" />
      </div>

      <div
        v-if="innerLoading"
        class="dropdown-search__loader"
      >
        <SpinnerIcon class="h-22 w-22 text-turquoise" />
      </div>

      <div
        v-if="isOpen"
        ref="dropdownRef"
        class="dropdown-search__dropdown px-20"
      >
        <p
          v-if="searchValue.length < minLength"
          class="py-10 text-14 font-bold"
        >
          {{ minLengthMessage }}
        </p>
        <p
          v-else-if="!innerOptions?.length && !innerLoading"
          class="pt-10 text-14 font-bold"
        >
          {{ noResultsMessage }}
        </p>

        <ul
          v-if="!!innerOptions?.length || (searchValue.length >= minLength && !innerLoading)"
          class="dropdown-search__list flex flex-col gap-10 py-10"
        >
          <li
            v-for="option in innerOptions"
            :key="option[trackBy]!"
          >
            <button
              class="flex w-full items-center justify-between gap-10 rounded-[12px] bg-turquoise px-12 py-10 text-left text-14 font-bold text-white"
              :class="{ '!bg-deep-blue': isSelected(option) }"
              type="button"
              @click="() => {
                addValue(option)
                close()
              }"
            >
              {{ getOptionLabel(option) }}
              <PlusIcon class="h-22 w-22" />
            </button>
          </li>

          <li>
            <button
              v-if="!isInput"
              class="flex w-full items-center justify-between gap-10 rounded-[12px] bg-warm-grey px-12 py-10 text-left text-14 font-bold text-white"
              type="button"
              @click="isInput = true"
            >
              Other not listed here
              <PlusIcon class="h-22 w-22" />
            </button>

            <div
              v-else
              class="dropdown-search__user-input"
            >
              <button
                class="absolute right-12 top-12 flex h-26 w-26 items-center justify-center rounded-full bg-turquoise text-white"
                type="button"
                @click="() => {
                  if (userInputValue.length === 0) {
                    return
                  }
                  addValue(customValue?.(userInputValue))
                  close()
                }"
              >
                <PlusIcon class="h-22 w-22" />
              </button>
              <input
                v-model="userInputValue"
                class="input w-full pr-50"
                type="text"
                :placeholder="searchInputPlaceholder"
                @keydown.enter="() => {
                  if (userInputValue.length === 0) {
                    return
                  }
                  addValue(customValue?.(userInputValue))
                  close()
                }"
              >
            </div>
          </li>
        </ul>
      </div>
    </BaseInputGroup>

    <div
      v-if="!!modelValue?.length"
      class="mt-48 flex flex-wrap items-start gap-10"
    >
      <div
        v-for="value in modelValue"
        :key="value[trackBy]!"
        class="flex cursor-default items-center justify-between gap-10 rounded-[12px] bg-turquoise px-12 py-10 text-14 font-bold text-white"
      >
        {{ getOptionLabel(value) }}

        <button
          type="button"
          @click="deleteValue(value)"
        >
          <CloseIcon class="h-22 w-22" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-search {
  position: relative;
  width: 100%;
}

.dropdown-search__input-group {
  z-index: 10;
  border-radius: 16px;
  box-shadow: $soft-shadow;

  :deep label {
    z-index: 20;
    padding-left: 48px;
  }
}

.dropdown-search__input {
  position: relative;
  z-index: 1;
  padding-left: 48px;
  padding-right: 46px;
}

.dropdown-search__search {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  pointer-events: none;
  transition: color 0.3s;
}

.dropdown-search__loader {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0 12px;
}

.dropdown-search__dropdown {
  position: absolute;
  top: calc(100% - 16px);
  left: 0;
  right: 0;
  max-height: 350px;
  padding-top: 16px;
  border-radius: 0 0 16px 16px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
  overflow: auto;
}

.dropdown-search__user-input {
  position: relative;

  button {
    z-index: 1;
  }

  input {
    box-shadow: 0 1px 4px #00000040 inset;
  }

  &:hover input {
    outline-color: theme('colors.turquoise');
  }
}
</style>
