<script setup lang="ts">
import { apiQuiz } from '@api/quiz';
import { DropdownSearch, BaseCheck, BaseInputGroup, BaseSelect } from '@components/base';
import { useFetch } from '@helpers/use-fetch';
import { PharmacyItem, PharmacySelect } from '@models/quiz';
import { useQuizStore } from '@stores/quiz';
import { debounce } from 'lodash';
import { vMaska } from 'maska';
import { ref, computed } from 'vue';

type Props = {
  modelValue?: PharmacyItem[]
  content: PharmacySelect
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value?: PharmacyItem[]]
}>();

const quizStore = useQuizStore();

const stateAnswer = computed(() => {
  const answerArray = Object.values(quizStore.answers).flat();
  return answerArray.filter((answer) => {
    return answer.slug === 'state';
  })[0]?.value;
});

const allergiesFetch = useFetch(apiQuiz.getPharmacies);
const loading = computed(() => allergiesFetch.loading.value);
const options = computed(() => allergiesFetch.response.value?.data || []);
const state = ref(stateAnswer.value);
const city = ref(props.modelValue?.[0]?.City || '');
const zip = ref('');
const speciality = ref('');

const search = debounce((name: string) => {
  allergiesFetch.execute({
    name,
    state: state.value,
    city: city.value,
    zip: zip.value,
    speciality: speciality.value,
  });
}, 800);

const stateModel = computed({
  get() {
    return props.content.states
      .find((option) => option.value === state.value);
  },
  set(option) {
    state.value = option?.value;
  },
});

const specialityModel = computed({
  get() {
    return props.content.specialities
      .find((option) => option.value === speciality.value);
  },
  set(option) {
    speciality.value = option?.value!;
  },
});

const pharmacyModel = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const noPharmacyModel = computed({
  get() {
    return props.modelValue?.length === 0;
  },
  set(value) {
    const newValue = value ? [] : undefined;
    emit('update:modelValue', newValue);
  },
});
</script>

<template>
  <div class="pharmacy-answer flex flex-col gap-20">
    <div class="f-row gap-y-20">
      <div class="f-col-5 md:f-col-3">
        <BaseInputGroup
          label="My state"
          :is-lock="!!state"
        >
          <BaseSelect
            v-model="stateModel"
            :options="content.states"
            label="label"
            track-by="value"
            placeholder=""
          />
        </BaseInputGroup>
      </div>

      <div class="f-col-5 md:f-col-2">
        <BaseInputGroup
          label="My city"
          :is-lock="!!city"
        >
          <input
            v-model="city"
            class="input w-full"
          >
        </BaseInputGroup>
      </div>
    </div>

    <div class="f-row gap-y-20">
      <div class="f-col-5 md:f-col-2">
        <BaseInputGroup
          label="ZIP code"
          :is-lock="!!zip"
        >
          <input
            v-model="zip"
            v-maska
            data-maska="AAAAA"
            data-maska-tokens="A:\d"
            data-maska-eager
            class="input w-full"
          >
        </BaseInputGroup>
      </div>

      <div class="f-col-5 md:f-col-3 md:order-first">
        <BaseInputGroup
          label="Speciality"
          :is-lock="!!speciality"
        >
          <BaseSelect
            v-model="specialityModel"
            :options="content.specialities"
            label="label"
            track-by="value"
            placeholder=""
          />
        </BaseInputGroup>
      </div>
    </div>

    <div class="f-row">
      <div class="f-col-5 flex flex-col items-center">
        <DropdownSearch
          v-model="pharmacyModel"
          :options="options"
          :loading="loading"
          :search-placeholder="content.placeholder ?? ''"
          search-input-placeholder="Your pharmacy"
          :min-length="3"
          min-length-message="Enter at least 3 characters"
          no-results-message="No pharmacy found"
          :option-label="(option: PharmacyItem) => [option.StoreName, option.Address1].filter(Boolean).join(', ')"
          :custom-value="(value) => ({
            Address1: null,
            Address2: null,
            City: city,
            Latitude: null,
            Longitude: null,
            PharmacyId: null,
            PharmacySpecialties: null,
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditional3: null,
            PhoneAdditionalType1: null,
            PhoneAdditionalType2: null,
            PhoneAdditionalType3: null,
            PrimaryFax: null,
            PrimaryPhone: null,
            PrimaryPhoneType: null,
            ServiceLevel: null,
            State: null,
            StoreName: value,
            ZipCode: null
          })"
          track-by="StoreName"
          @search="search"
        />

        <BaseCheck
          v-if="content.skip"
          v-model="noPharmacyModel"
          class="mt-30 w-full sm:mt-48"
          :size="27"
          type="checkbox"
          styled
          :data-testid="noPharmacyModel
            ? 'quiz-checkbox-selected'
            : 'quiz-checkbox'"
        >
          I don’t have a preferred pharmacy
        </BaseCheck>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
